.item {
  border: 2px solid green;
  margin: 2px;
}

.droap_area {
  border: 2px red solid;
  padding: 100px;
}

.text-green {
  color: rgba(0, 98, 255, 1);
}

.navbar .navbar-nav .nav-item.active-NavDropdown .nav-link {
  color: #0062ff;
}

.navbar .navbar-nav .nav-item.active-NavDropdown .nav-link::before {
  background-color: #0062ff;
  transition: 300ms;
  height: 3px;
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
}

.source-status-red {
  margin-right: 10px;
  color: #ea5455;
}

.source-status-green {
  color: #22c55e;
}

.dashboard-filter .filter-input:nth-child(4) select {
  background-color: transparent;
}

.name-with_status .source-status-green {
  margin-right: 3px;
}

.back-arrow {
  margin: 60px 0 !important;
}
.flat-arrow {
  font-size: 20px;
  margin-bottom: 50px;
}

.MTableToolbar-searchField-10 {
  padding-left: 0 !important;
}

.MuiTextField-root .jss10 {
  padding-left: 0 !important;
}

.MTableToolbar-searchField-10 {
  padding-left: 0 !important;
}
.jss10 {
  padding-left: 0 !important;
}
.jss7 {
  display: none;
}

.jss20 {
  display: none;
}

[class*='jss'] {
  /* display: none; */
  padding-left: 0 !important;
}

ul.usergroup {
  padding: 0;
  margin: 0;
  margin: 0;
  position: relative;
}
ul.usergroup li {
  list-style: none;
  display: inline-block;
  float: left;
  margin-left: -15px;
}
ul.usergroup li.usercount {
  position: absolute;
  top: 4px;
}
ul.usergroup li.usercount span {
  font-size: 8px;
  font-weight: 600;
  color: #fff;
}
ul.usergroup li img {
  -o-object-fit: cover;
  object-fit: cover;
}
.rounded-circle {
  border-radius: 50% !important;
}
.user-count {
  color: rgba(0, 98, 255, 0.8);
  font-size: 18px !important;
  display: block;
}

.danger {
  background-color: red !important;
  border-color: red !important;
}

.dashboard-filter {
  display: flex;
  justify-content: center;
}

.validate {
  text-align: right;
  margin-right: 80px;
  margin-top: 45px;
  color: red;
  font-size: 12px;
  font-weight: bold;
}

.capitalize-first {
  text-transform: lowercase;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}