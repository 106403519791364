@use 'sass:map';
//font families
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,700,500|Poppins:400,300,500,600|Inter:400,\
300,500,600');
$poppins: 'Poppins', sans-serif;
$inter: 'Inter', sans-serif;
$Roboto: 'Roboto', sans-serif;

// colors
$white: #fff;
$lwhite: #f1f1f5;
$black: #000;
$blue: #0062ff;
$skyblue: #50b5ff;
$llblue: #565e75;
$dgrey: #44444f;
$lgrey: #9292b5;
$red: #ff767a;
// 1rem = 10px

// Paragraphs
$primary-font-size: 16px;
$secondary-font-size: 14px;
$tertiary-font-size: 12px;

// Headings
$h1-font-size: 5.6rem;
$h2-font-size: 4.2rem;
$h3-font-size: 2.4rem;
$h4-font-size: 18px;
$h5-font-size: 2rem;
$h6-font-size: 16px;

// text colors
.text-black {
  color: $black !important;
}
.text-white {
  color: $white !important;
}

.text-blue {
  color: $blue !important;
}
.text-llblue {
  color: $llblue !important;
}
.text-dgrey {
  color: $dgrey !important;
}
.text-lgrey {
  color: $lgrey !important;
}
.text-red {
  color: $red !important;
}

/*bg colors*/
.bgwhite {
  background-color: $lwhite;
}
.bgblue {
  background-color: $blue;
}
.bglwhite {
  background-color: #fafafb;
}
.h-100vh {
  min-height: 50vh;
}
/*margins*/
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-75 {
  margin-bottom: 75px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mb-85 {
  margin-bottom: 85px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.px-6 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.mr-60 {
  margin-right: 60px !important;
}

.min100vh {
  min-height: 100vh;
}

.pointer {
  cursor: pointer !important;
}
.text-decoration-border {
  border-bottom: 1px solid;
}
html,
body {
  height: 100%;
  font-size: 16px;
}
p {
  font-size: $primary-font-size;
  font-weight: 400;
  color: $dgrey;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-12:hover {
  color: gray;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18rem !important;
}
.fs-20 {
  font-size: 2rem !important;
}
.fs-24 {
  font-size: 2.4rem !important;
}
.fs-30 {
  font-size: 3rem !important;
}
a {
  color: $blue;
  text-decoration: none;
  font-weight: 400;
  &:hover {
    text-decoration: none;
  }
}
.underline {
  text-decoration: underline;
}

h1,
.h1 {
  font-weight: 500;
  font-size: $h1-font-size;
}
h2,
.h2 {
  font-size: $h2-font-size;
  font-weight: 500;
}
h3,
.h3 {
  font-size: $h3-font-size;
  font-weight: 600;
}
h4,
.h4 {
  font-size: $h4-font-size;
  font-weight: 500;
}
h5,
.h5 {
  font-size: $h5-font-size;
  font-weight: 500;
}
h6,
.h6 {
  font-size: $h6-font-size;
  font-weight: 500;
}
.fw-light {
  font-weight: 300;
}
.fw-reg {
  font-weight: 400;
}
.fw-med {
  font-weight: 500;
}
.fw-semi {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.w-280 {
  width: 280px;
  p:first-child {
    width: 100% !important;
  }
}
.zindex-1 {
  z-index: -1;
}
.zindex-0 {
  z-index: 0;
}
input,
button,
a,
textarea,
textarea:focus,
.form-control,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  outline: 0;
  box-shadow: none;
  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
textarea {
  resize: none;
}
@mixin transition {
  transition: all 0.3s ease-in-out;
}

.container-fluid {
  padding-left: 26px;
  padding-right: 26px;
}
body .react-tel-input .form-control {
  width: 100% !important;
  padding-left: 110px;
  border: 1px solid #9292b5;
  border-radius: 10px;
  height: 48px;
}
body .react-tel-input .flag-dropdown {
  width: 100px;
  border: 0;
  background: transparent;
  height: 48px;
  border-right: 1px solid #9292b5;
}
body .react-tel-input .selected-flag {
  width: 100px;
}
body .react-tel-input .selected-flag:hover,
body .react-tel-input .selected-flag:focus {
  background: transparent;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #9292b5;
}
body .react-tel-input .selected-flag:hover {
  background: transparent;
  border: 1px solid #9292b5;
}
body .react-tel-input .flag-dropdown.open {
  background: transparent;
}
body .react-tel-input .flag-dropdown.open .selected-flag {
  border: 1px solid #9292b5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
//loader
.lds-roller {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $white;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
.overlay {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.minh-100vh {
  min-height: 100vh;
}

.btn-primary {
  height: 48px;
  line-height: 1;
  border: 1px solid $blue;
  background-color: $blue;
  color: $white;
  border-radius: 6px;
  font-size: 14px;
  font-family: $inter;
  font-weight: 500;
  padding: 8px 34px;
  @include transition;
  &:hover {
    background: rgba($blue, 0.8);
    border: 1px solid rgba($blue, 0.8);
  }
  &:focus,
  .focus {
    background: rgba($blue, 0.8);
    border: 1px solid rgba($blue, 0.8);
    box-shadow: none;
    outline: 0;
  }
  &.btn-md {
    min-height: 40px;
    min-width: 160px;
    font-size: 16px;
    padding: initial;
  }
}
.btnwhite {
  line-height: 1;
  border: 1px solid $lgrey;
  background-color: $white;
  color: $dgrey;
  border-radius: 8px;
  font-size: 14px;
  font-family: $inter;
  font-weight: 500;
  padding: 10px 25px;
  @include transition;
  &:hover {
    border: 1px solid $blue;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: rgba($blue, 0.8);
  border: 1px solid rgba($blue, 0.8);
  box-shadow: none;
  outline: 0;
}

.navbar {
  padding: 10px 0;
  .navbar-nav .nav-link {
    color: $black;
    font-size: 16px;
    margin-left: 36px;
    font-family: $inter;
    &.active {
      color: $blue;
    }
  }
}

// Collapsable menu in navbar
.navbar-toggler {
  padding: 0.2rem 0.55rem;
  font-size: 1rem;
}

.loginpage {
  background-image: url(../images/blushape.png);
  background-size: 100% 45%;
  background-repeat: no-repeat;
  padding: 30px 0 100px 0;
  &.nobg {
    background-image: none;
  }
}

.carousel-container {
  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-indicators {
    bottom: 2rem;
  }

  .carousel-indicators > button {
    background-color: #989898 !important;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
  }

  /* Fix for flickering carousel animation*/
  .carousel-inner .carousel-item {
    opacity: 0;
  }
  .carousel-inner .active {
    opacity: 1;
  }
  /* End of fix */
}

.mainform {
  max-width: 490px;
  margin: 50px auto 0 auto;
  text-align: center;
  h1,
  p {
    color: $white;
  }
}
.authform {
  .bg {
    padding: 50px 50px 0 50px;
  }
  border-radius: 20px;
  box-shadow: 3px 2px 40px #cdcdd440;
  background: $white;
  &.settingform {
    border: 0;
    box-shadow: none;
    background: none;
  }
  label {
    font-size: 12px;
  }
  .input {
    border-radius: 10px;
    height: 48px;
    border: 1px solid $lgrey;
    padding-top: 9px !important;
    padding-left: 19px;
    font-size: 14px;
  }
  .select {
    border-radius: 10px;
    height: 48px;
    border: 1px solid $lgrey;
    padding-top: 9px !important;
    padding-left: 19px;
    font-size: 14px;
  }
  .form-check-input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
.passwrapper {
  position: relative;
  .pass-toggle {
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    width: 16px;
  }
}
.authbottom {
  background: #fafafb;
  text-align: center;
  padding: 16px;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 70px;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-8px) translateX(0.15rem);
  opacity: 1;
  background: #fff;
  top: 0;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  border: 1px solid $skyblue;
}
.form-floating > .form-control:focus + label,
.form-floating > .form-control:not(:placeholder-shown) + label {
  color: $skyblue;
}
.form-floating > label {
  padding: 0;
  height: auto;
  font-size: 12px;
  font-family: $inter;
  margin-left: 16px;
  width: auto;
  top: 15px;
}
.invalid-feedback {
  display: block;
  text-align: left;
  font-size: 12px;
}

.forgotpass {
  .bg {
    padding-bottom: 60px;
  }
}

.mainheading {
  padding: 35px 0;
}

.accountform {
  max-width: 846px;
  text-align: left;
  margin-top: 100px;
  margin-bottom: 100px;
  label {
    font-size: 18px;
    font-weight: 500;
  }
  .bg {
    padding-bottom: 50px;
  }
}
.avatar {
  display: block;
  border: 2px solid #0062ff;
  object-fit: cover;
}
#file-upload {
  display: none;
}

.dropdown-menu {
  border-radius: 8px;
  box-shadow: 3px 2px 26px #f5f2f2;
  border: 0;
  a {
    font-size: 14px;
    padding: 6px 10px;
  }
}
.profilepic {
  position: relative;
  display: inline-block;
  i {
    font-size: 18px;
    width: 30px;
    height: 30px;
    background: #eedede;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
  }
}

.fileuplaod {
  display: block;
  position: absolute;
  height: auto;
  bottom: 0;
  right: -5px;
}

.bluebordertabs {
  &.nav-tabs {
    border: 0;
    .nav-link {
      background-color: transparent;
      padding: 10px 0;
      margin: 0 45px 0 0;
      font-size: 16px;
      border: 0;
      color: $black;
    }
    .nav-link.active {
      border-bottom: 2px solid $blue;
      color: $blue;
    }
    .nav-link.disabled {
      color: $lgrey;
    }
  }
}

.formnames {
  a {
    color: $skyblue;
    font-weight: 600;
  }
  span {
    font-size: 12px;
    color: $dgrey;
    margin-right: 5px;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.MuiTablePagination-root {
  background: #fafafb;
}
.mytable1 {
  thead {
    th {
      text-align: center;
      &:nth-child(2) {
        text-align: left;
      }
    }
  }
  tbody {
    td {
      text-align: center;
      &:nth-child(2) {
        text-align: left;
      }
      &:nth-child(5) {
        color: $skyblue !important;
      }
    }
  }
}
.bordertable {
  .MuiTableRow-root {
    border-bottom: 6px solid #fafafb;
  }
  thead {
    border-top: 6px solid #fafafb;
  }
}

.tableimgwithtxt {
  margin-left: 15px;
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  p {
    color: $skyblue;
    font-weight: 500;
  }
}

body .MuiIconButton-root .MuiSvgIcon-root path {
  color: #ada5a5;
}

.custom-padding {
  padding: 0 5rem;
}

.navbar .navbar-nav .nav-link {
  position: relative;
  padding: 1.2rem 0;
}

.navbar .navbar-nav .nav-link::before,
.navbar .navbar-nav .nav-link.active::before {
  transition: 300ms;
  height: 3px;
  content: '';
  position: absolute;
  background-color: $blue;
  width: 0;
  left: 0;
  bottom: 5px;
}

.navbar .navbar-nav .nav-link:hover::before,
.navbar .navbar-nav .nav-link.active::before {
  width: 100%;
}

/* ===================================
    Router Main Page
====================================== */
.routerMainpage {
  margin-top: 80px;
  // padding: 0 3rem;
  & .heading-area {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  & .heading-area button {
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    font-family: $inter;
    min-height: 50px;
    min-width: 130px;
    border-radius: 6px;
  }
  & .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 12px;
    background-color: #fafafb;
  }
  & .bordertable tr.MuiTableRow-root {
    background-color: $white;
  }
  & .MuiTableCell-head {
    padding: 10px 5px;
  }
}

/* ===================================
    Lead Source Tab
====================================== */
.leadSource-page {
  background: #fafafb;
  min-height: 100vh;
}

.leadSource-page .leadWrapper {
  // padding: 0 80px;
  margin: 100px 0;
}

.leadSource-page .leadWrapper h2 {
  font-size: 22px;
  font-weight: 600;
  color: #44444f;
  font-family: $inter;
}

.leadSource-page table {
  background: #fafafb;
}

.leadSource-page .MuiTableCell-root {
  padding: 15px 30px !important;
}

.leadSource-page .leadWrapper .webhookBox {
  background: #ffffff;
  padding: 60px 80px;
  & .boxInner {
    display: flex;
  }
  & h4 {
    color: #44444f;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 25px;
    font-family: $inter;
  }
  & input {
    width: 100%;
    color: #e2e2ea;
    font-size: 18px;
    font-weight: normal;
    border-radius: 7px;
    letter-spacing: 0.13px;
    margin-right: 30px;
    line-height: 18px;
    font-family: $inter;
    padding: 10px;
    border: 1px solid #e2e2ea;
  }
  & .btn.btn-blue {
    font-weight: 500;
    font-size: 16px;
    color: $white;
    background: $blue;
    font-family: $inter;
    min-height: 50px;
    min-width: 240px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  & .btn.btn-blue img {
    width: 25px;
    margin-right: 10px;
  }
}

.leadSource-page .MuiToolbar-regular {
  min-height: 0;
}

.leadSource-page .MuiTable-root {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.leadSource-page .MuiTableCell-head {
  padding: 16px 20px;
}

.leadSource-page .MuiTableCell-body {
  color: #ffffff !important;
  padding: 16px 20px;
}

.margin-left20 {
  margin-left: 15px !important;
}

.leadSource .MuiToolbar-regular {
  min-height: 0;
}

.logo {
  width: 100px;
  display: inline-block;
  & a {
    width: 100%;
    display: inline-block;
    text-decoration: none;
  }
  & span {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
  }
}

.table-search-bar .MuiInput-underline:before,
.table-search-bar .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.table-search-bar .MuiInput-underline {
  border: 1px solid #e2e2ea;
  border-radius: 7px;
  height: 38px;
  background: $white;
}

// .table-search-bar .MuiIconButton-label {
//   display: none;
// }

// .table-search-bar .MuiIconButton-root.Mui-disabled {
//   display: none;
// }

.table-search-bar .MuiInputAdornment-positionEnd {
  display: none;
}

.table-search-bar .MuiInputAdornment-positionStart {
  margin-right: 8px;
  position: absolute;
  right: 0;
}

.table-search-bar .MuiInputBase-input {
  padding: 6px 15px 7px;
}

.bg-darkGrey {
  background-color: #f5f5f6;
}

.MuiTableCell-root {
  padding: 12px 5px !important;
}

.MuiTable-root tbody tr td,
.MuiTable-root tbody tr .MuiTableCell-root div {
  font-size: 14px !important;
}

.MuiPaper-root > div:nth-child(1) {
  background: #fafafb;
}

.MTableToolbar-searchField-10 {
  padding-left: 0 !important;
}

.model-headingArea p {
  font-weight: 500;
  font-size: 16px;
  color: #0062ff;
  display: inline-block;
  border-bottom: 2px solid #0062ff;
  font-family: $inter;
  margin-top: 20px;
  padding-bottom: 3px;
}

.model-tabs {
  & h4 {
    color: #44444f;
    font-size: 20px;
    font-weight: 400;
    font-family: $inter;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  & .selectable-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
  }
  & .selectable-tabs > div {
    background-color: #0062ff;
    border-radius: 4px;
    width: 30%;
    padding: 8px 0;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    font-family: $inter;
    cursor: pointer;
    font-weight: 500;
    border: 3px solid transparent;
    @include transition();
    position: relative;
  }
  & .selectable-tabs > div.active {
    background: rgba(0, 98, 255, 0.6);
    border: 3px solid $blue;
  }
  & .selectable-tabs > div i {
    visibility: hidden;
  }
  & .selectable-tabs > div.active i {
    color: #ffffff;
    font-size: 15px;
    visibility: visible;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.custom--checkbox input {
  position: absolute;
  left: 2%;
}

.draggable {
  float: left;
  margin: 0 10px 10px 0;
}

.model-tab-sec {
  margin-bottom: 50px;
}

.model-tab-sec .MuiPaper-root > div:nth-child(1) {
  display: none !important;
}

.model-tab-sec .table-search-bar h4 {
  margin-bottom: 15px;
  color: #44444f;
  font-size: 20px;
  font-weight: 400;
  font-family: $inter;
}

.review-publish .MuiPaper-root > div:nth-child(1) {
  display: none !important;
}

.review-publish .table-search-bar h4 {
  margin-bottom: 25px;
  margin-top: 30px;
  color: #44444f;
  font-size: 20px;
  font-weight: 400;
  font-family: $inter;
}

.model-tabs.distri-model {
  & .selectable-tabs {
    margin: 40px 0 10px 0;
  }
  & p {
    color: #707070;
    font-size: 16px;
    font-weight: 400;
    font-family: $inter;
  }
}

.editPencil-icon {
  width: 13px;
  margin-left: 10px;
}
.text-white {
  color: white;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.refresh-btn {
  & .selectable-tabs > Button {
    background-color: #0062ff;
    border-radius: 4px;
    width: 30%;
    padding: 8px 0;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    font-family: $inter;
    cursor: pointer;
    border: 3px solid transparent;
    @include transition();
  }
  & Button:hover {
    background: rgba(0, 98, 255, 0.6);
    border: 3px solid $blue;
  }
  & Button i {
    color: #ffffff;
    font-size: 15px;
    margin-right: 15px;
  }
}

.model-table-padding .MuiTableCell-root {
  padding: 12px 50px !important;
}

.percentage-box input {
  height: 39px;
  min-width: 76px;
  padding: 12px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #e2e2ea;

  & span {
    color: #e2e2ea;
    font-size: 18px !important;
    font-weight: 400;
    font-family: $inter;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
  flex-direction: row-reverse !important;
  padding: 0;
}

.lead-subNav {
  & .mainheading {
    color: #44444f;
    font-size: 24px;
    font-weight: 600;
    font-family: $inter;
    & > div {
      display: flex;
      flex-wrap: wrap;
    }
    & input {
      min-height: 42px;
      font-size: 20px;
      min-width: 400px;
      margin-right: 20px;
      padding: 0 10px;
      border-radius: 4px;
      background: #ffffff;
      border: 2px solid #e2e2ea;
    }
    & button {
      font-size: 16px;
      min-height: 42px;
      color: #ffffff;
      border-radius: 4px;
      font-weight: 500;
      padding: 0 24px;
      cursor: pointer;
      border: 3px solid #0062ff;
      background-color: #0062ff;
      transition: all 0.3s ease-in-out;
      font-family: $inter;
      &:hover {
        background: rgba(0, 98, 255, 0.5);
        border: 3px solid rgba(0, 98, 255, 1);
      }
    }
  }
}

.dashboard-filter {
  padding: 30px 20px;
  display: flex;
  border-radius: 6px;
  align-items: flex-start;
  & .filter-input {
    margin-right: 25px;
  }
  & .filter-input label {
    letter-spacing: 0.1px;
    line-height: 14px;
    color: #44444f;
    font-size: 14px;
    font-weight: 400;
    font-family: $inter;
    margin-bottom: 8px;
    display: block;
  }
  & .filter-input input {
    border-radius: 8px;
    padding: 8px 13px;
    border: 1px solid #e2e2ea;
    font-size: 14px;
    color: #202020;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #e2e2ea;
      font-size: 14px;
      font-family: $inter;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e2e2ea;
      font-size: 14px;
      font-family: $inter;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e2e2ea;
      font-size: 14px;
      font-family: $inter;
    }
  }
  & .filter-input img {
    position: absolute;
    width: 18px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.dashboard-filter .filter-input:nth-child(1) input {
  width: 320px;
}

.dashboard-filter .filter-input:nth-child(4) {
  margin-top: 20px;
}

.dashboard-filter .filter-input:nth-child(4) label {
  display: inline-block;
  margin-bottom: 0;
}

.dashboard-filter .filter-input:nth-child(4) select {
  border: none;
  color: #0062ff;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-family: $inter;
}
.name-with_status {
  display: flex;
  align-items: baseline;

  & .source-status-green {
    height: 8px;
    width: 8px;
    margin-left: 3px;
    border-radius: 50px;
    background: #22c55e;
  }
  & .source-status-red {
    height: 8px;
    width: 8px;
    margin-left: 3px;
    border-radius: 50px;
    background: #ea5455;
  }
}

.request-waiting {
  text-align: center;
  margin: 80px 0 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & h2 {
    margin-left: 30px;
    letter-spacing: 0.17px;
    font-size: 24px !important;
    font-weight: 500 !important;
  }
}

// Sources Page Waiting Loader
.wait-loader {
  width: 30px;
  height: 30px;
  border: solid 4px #44444f;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1.3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ml-5 {
  margin-left: 10px;
}

.home-show-select {
  width: 180px;
}

.home-show-select .react-jaycon__control {
  border-radius: 8px;
  padding: 2px 0px;
  border: 1px solid #e2e2ea;
  font-size: 14px;
  color: #202020;
}

.home-show-select .react-jaycon__indicators {
  height: 32px;
}

.home-show-select .form-control-plaintext {
  padding: 0;
}

.dashboard-filter .home-show-select.filter-input label {
  margin-bottom: 7px;
}

.applyFilter-btn {
  padding: 1em 2em;
  border: 0;
  outline: 0;
  color: #f5f5f5;
  cursor: pointer;
  background: #0062ff;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  font-weight: 500;
  font-family: $inter;
  padding: 7px 24px;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 20px;

  &:hover {
    background-color: transparent;
    color: #0062ff;
    border-color: #0062ff;
    cursor: pointer;
  }
}

.home-show-select .react-jaycon__value-container {
  display: grid;
  grid-auto-rows: 30px;
}

.home-show-select .form-control-plaintext * {
  z-index: 9999;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.routerMainpage .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  justify-content: left;
  width: 100% !important;
  flex-direction: row-reverse;
}

table.MuiTable-root .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  justify-content: initial !important;
  flex-direction: row !important;
}

.tg-list {
  text-align: center;
  display: flex;
  align-items: center;
}

.tg-list-item {
  margin: 0;

  & h4 {
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    color: #44444f;
    font-family: $inter;
    margin: 30px 0 0 0;
  }

  & p {
    font-size: 13px;
    width: 80%;
    text-align: left;
    font-weight: 400;
    color: #44444f;
    line-height: 1.8;
    font-family: $inter;
    margin: 8px 0 15px 0;
  }
}

.tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 70px;
  height: 34px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: '';
  width: 45%;
  height: 100%;
}

.tgl + .tgl-btn:after {
  left: 0;
}

.tgl + .tgl-btn:before {
  display: none;
}

.tgl:checked + .tgl-btn:after {
  left: 55%;
}

.tgl-toggle + .tgl-btn {
  background: #8d8d8d;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}

.tgl-toggle + .tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}

.tgl-toggle + .tgl-btn:hover:after {
  will-change: padding;
}

.tgl-toggle + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}

.tgl-toggle + .tgl-btn:active:after {
  padding-right: 0.8em;
}

.tgl-toggle:checked + .tgl-btn {
  background: #0062ff;
}

.tgl-toggle:checked + .tgl-btn:active {
  box-shadow: none;
}

.tgl-toggle:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

// Assignment Status Toggle Btn
.assignment-status {
  & .tgl + .tgl-btn {
    width: 52px;
    height: 26px;
  }

  & .tgl-toggle:checked + .tgl-btn {
    background: green;
  }
}

// intergatatpion of HubSports
.integrations-page {
  padding: 120px 0;
  min-height: 100vh;
  position: relative;

  & .centered-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  & h2 {
    display: block;
    font-size: 24px;
    color: #44444f;
    font-weight: 600;
    text-align: center;
  }
}

.connect-page {
  margin: 80px 0;
  border-radius: 4px;
  background: #ffffff;
  min-height: 100vh;
  padding: 30px;

  & .connect-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  & .connect-box {
    width: 200px;
    min-height: 200px;
    background: #f5f5f6;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    margin: 12px;
    flex-direction: column;
    justify-content: space-between;

    & .connectLogo {
      padding: 20px;
      & img {
        width: 120px;
        max-width: inherit;
      }
    }

    & h6 {
      background: #f3f3f3;
      color: #707070;
      font-size: 18px;
      font-weight: 600;
      font-family: $inter;
      margin-bottom: 0;
      padding: 15px;
    }
  }
}

.connect-page.connect-detail {
  background: transparent;
  padding: 0;

  & .connect-box {
    margin: 0 40px 0 0;
    height: 220px;
    width: 18%;
  }

  & .connect-wrapper {
    flex-wrap: initial;
  }

  & .connect-description {
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & h3 {
      color: #707070;
      font-weight: 600;
      margin-bottom: 0;
      font-size: 28px;
    }

    & p {
      color: #707070;
      font-size: 16px;
      margin: 15px 0;
      font-weight: 500;
    }
  }

  & .connect-tabs {
    margin-top: 50px;
    border-radius: 8px;
    overflow: hidden;

    & .connect-navbar {
      background: rgba(228, 228, 228, 0.6);
      overflow: hidden;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);

      & > div {
        padding: 20px 30px;
        display: inline-block;
        color: #707070;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer !important;
      }

      & div.active {
        box-shadow: 1px 0px 15px #00000029;
        background: rgba(255, 255, 255, 0.4);
      }
    }

    & .tabs-body {
      background: #f5f5f6;
      padding: 50px;

      & p {
        font-size: 16px;
        color: #707070;
        font-weight: normal;
        line-height: 1.8;
      }

      & h5 {
        font-size: 18px;
        color: #707070;
        font-weight: normal;
        margin-bottom: 20px;
      }

      & ul li {
        font-size: 15px;
        color: #707070;
        font-weight: normal;
        margin-bottom: 12px;
      }
    }
  }
}

.custom-modalBox {
  padding: 10px;

  & .modal-header {
    border: none;
  }

  & .authform {
    background: transparent;
    box-shadow: none;
  }

  & .mainform {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .routera-form-input {
    width: 85%;
    text-align: center;

    & input {
      height: 42px;
      border-color: #e2e2ea;
      border-radius: 6px;
      margin: 35px 0 45px 0;
    }
  }

  & .btn-primary {
    opacity: 1;
    height: 45px;
    margin-bottom: 30px;
  }
}

.chooseconn-modal {
  & .connect-page {
    margin: 0;
    min-height: auto;
    padding: 0 0 30px 0;
    & .connect-wrapper {
      flex-wrap: initial;
    }
    & .connect-box {
      width: 185px;

      & h6 {
        font-size: 16px;
      }
    }

    & .connect-box.active {
      background: rgba(0, 98, 255, 0.7);

      & h6 {
        background: #0062ff;
        color: #ffffff;
      }
    }

    & .connect-box .connectLogo {
      padding: 15px;

      & img {
        width: 115px;
      }
    }
  }
}

.hubspot-list-page {
  & h3 {
    font-size: 20px;
    color: #44444f;
    font-weight: 500;
  }
}

.hubspot-list-page.leadSource-page .MuiTableCell-body {
  color: #000 !important;
}

.connIntegration-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
}

.working-hours {
  margin-top: 30px;
  padding-left: 0;
  list-style: none;
  display: block;
}

.working-hours .selectdiv {
  position: relative;
  width: 140px;
}

.working-hours .selectdiv input {
  width: 100%;
  padding: 0 12px;
  height: 35px;
  font-size: 14px;
  border-radius: 8px;
  font-family: $inter;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #9292b5;
}

// .working-hours .selectdiv:after {
//   content: "<>";
//   color: #333;
//   font: 16px "Poppins", sans-serif;
//   -webkit-transform: rotate(90deg);
//   -moz-transform: rotate(90deg);
//   -ms-transform: rotate(90deg);
//   transform: rotate(90deg);
//   right: 10px;
//   top: 5px;
//   padding: 0 0 2px;
//   position: absolute;
//   pointer-events: none;
// }

.wHours-row {
  width: 85%;
  display: flex;
  margin: 15px 0;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & .invalid-time {
    position: absolute;
    top: 50%;
    right: -18%;
    transform: translateY(-50%);
    font-size: 14px;
    text-transform: capitalize;
    color: red;
    font-weight: normal;
  }

  & h6 {
    margin-bottom: 0;
    min-width: 100px;
    text-align: left;
    text-transform: capitalize;
  }

  & .tgl + .tgl-btn {
    width: 48px;
    height: 24px;
  }

  .compare-to {
    font-size: 15px;
    font-weight: 500;
  }
}

.select-default_user {
  margin: 40px 0;

  & label {
    display: block;
    font-size: 2.2rem;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 15px;
  }

  & select {
    width: 250px;
    height: 35px;
    font-size: 15px;
    border-radius: 6px;
    border: 1px solid #e2e2ea;
    background: #ffffff;
  }
}

.publish-row {
  // display: flex;
  align-items: center;
  justify-content: end;
  margin: 40px 0;
}

$breakpoints: (
  xs: 0,
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
);

.privacy-heading {
  text-align: center;
  color: #ffffff;
  margin-top: 12rem;
}

.privacy-content {
  margin-top: 20rem;
}

.tailwind-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1.5rem;

  & thead {
    display: table-header-group;
    background-color: #f1f1f5;

    & tr {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }

    & th {
      padding: 14px 30px;
      color: #b7b7c0;
      font-weight: 500;
      line-height: 18px;
      text-transform: capitalize;
      justify-self: flex-start;
      align-items: center;
      flex-grow: 1;
    }
  }

  & tbody {
    & tr {
      display: flex;
      margin-bottom: 10px;
    }
    & td {
      flex-grow: 1;
      align-self: flex-start;
      padding: 14px 30px;
      background: #ffffff;
      width: 100%;
      & .tableimgwithtxt {
        margin-left: 0;

        & p {
          font-weight: normal;
          font-size: 14px;
        }
      }

      & .orderNo {
        font-weight: 500;
        text-align: center;
        display: inline-block;
        height: 30px;
        justify-self: flex-start;
        flex-grow: 1;
      }

      & input {
        font-size: 16px;
        font-weight: 500;
        color: #202020;
        text-align: center;
        width: 80px;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #d4d4d6;
      }
    }
  }
}

.timezone-dropdown {
  & select {
    margin-top: 15px;
    text-align: left;
    display: block;
    padding: 0 10px;
    height: 38px;
    width: 220px;
    font-size: 16px;
    border-radius: 8px;
    font-weight: normal;
    background: #ffffff;
    font-family: $inter;
    border: 1px solid #9292b5;
  }
}

@media (min-width: map.get($breakpoints, sm)) {
  .privacy-content {
    margin-top: 18rem;
  }
}
@media (min-width: map.get($breakpoints, lg)) {
  .privacy-heading {
    margin-top: 20rem;
  }
  .privacy-content {
    margin-top: 22rem;
    padding: 0 20rem;
  }
}

@media (min-width: 1800px) {
  .connect-page.connect-detail .connect-box {
    width: 14%;
  }
}

@media (max-width: 1440px) {
  .loginpage {
    background-size: 100% 40%;
  }
  .dashboard-filter .filter-input:nth-child(1) input {
    width: 220px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .connect-page.connect-detail .connect-box {
    width: 32%;
  }
}

@media (max-width: 991px) {
  .model-tabs .selectable-tabs {
    width: 100%;
  }
  .model-tabs h4 {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .connect-page .connect-wrapper {
    justify-content: space-evenly;
  }
  .connect-page.connect-detail .connect-box {
    width: 45%;
  }
  .wHours-row {
    width: 100%;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .routerMainpage {
    margin-top: 40px;
  }
  .px-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .authform .bg {
    padding: 50px 0px 0 0px;
  }
  .routers-page.routerMainpage {
    padding: 0 3rem;
  }
  .leadSource-page .leadWrapper .webhookBox {
    background: #ffffff;
    padding: 40px 40px;
  }
  .dashboard-filter {
    flex-wrap: wrap;
  }
  .dashboard-filter .filter-input:nth-child(3) {
    margin-top: 20px;
  }
  .applyFilter-btn {
    margin-top: 38px;
  }
}

@media (max-width: 767px) {
  body .react-tel-input .flag-dropdown,
  body .react-tel-input .selected-flag {
    width: 40px;
  }
  body .react-tel-input .form-control {
    padding-left: 48px;
  }
  .authform {
    br {
      display: none;
    }
  }
  .avatar {
    width: 100px;
    height: 100px;
    border: 4px solid #0062ff;
    margin-top: -100px;
  }
  .fileuplaod {
    bottom: -10px;
    right: 45px;
  }
  .accountform {
    margin-top: 45px;
  }
  .navbar .navbar-nav .nav-link {
    margin-left: 0;
  }
  .authform .bg {
    padding: 15px 15px 15px 15px;
  }
  .form-check-label {
    display: block;
  }
  .authbottom {
    margin-top: 0;
  }
  .mb-30 {
    margin-bottom: 15px !important;
  }
  .custom-padding {
    padding: 0 2rem;
  }
  .avatar {
    width: 80px;
    height: 80px;
    border: 4px solid #0062ff;
    margin-top: -18px;
  }
  .accountform label {
    font-size: 16px;
  }
  .authform .input {
    height: 42px;
    padding-top: 5px !important;
  }
  .mainheading {
    padding: 25px 0;
  }
  .px-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .routerMainpage {
    margin-top: 40px;
  }
  .routers-page.routerMainpage {
    padding: 0 3rem;
  }
  .routerMainpage .heading-area button {
    min-height: 42px;
    min-width: 120px;
  }
  .leadSource-page .leadWrapper .webhookBox .boxInner {
    flex-wrap: wrap;
  }
  .leadSource-page .leadWrapper .webhookBox {
    background: #ffffff;
    padding: 30px 15px;
  }
  .leadSource-page .leadWrapper .webhookBox input {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .bluebordertabs.nav-tabs .nav-link {
    margin: 0 20px 0 0;
    font-size: 14px;
  }
  .model-tabs .selectable-tabs {
    flex-wrap: wrap;
    & > div {
      width: 100%;
      &:nth-child(2) {
        margin: 15px 0;
      }
    }
  }
  .mt-60 {
    margin-top: 40px !important;
  }
  h3 {
    font-size: 2.2rem;
  }
  .btn-primary {
    height: 42px;
    padding: 8px 30px;
  }
  .lead-subNav .mainheading {
    font-size: 18px;
    & input {
      min-width: 100%;
      font-size: 16px;
      margin: 0 0 20px 0;
    }
    & button {
      height: 42px;
      font-size: 14px;
      padding: 8px 30px;
    }
  }
  .dashboard-filter {
    flex-wrap: wrap;
  }
  .dashboard-filter .filter-input {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .applyFilter-btn {
    margin-top: 10px;
  }
  .tg-list-item p {
    width: 100%;
  }
  .integrations-page {
    padding: 80px 0;
    & h2 {
      font-size: 20px;
    }
  }
  .connect-page {
    padding: 30px 0;
  }
  .connect-page .connect-box {
    width: 150px;
    margin: 8px;
  }
  .connect-page .connect-box .connectLogo img {
    width: 100px;
  }
  .connect-page.connect-detail .connect-wrapper {
    flex-wrap: wrap;
  }
  .connect-page.connect-detail .connect-box {
    width: 65%;
    margin-bottom: 30px;
  }
  .connect-page.connect-detail .connect-description {
    width: 100%;
  }
  .connect-page.connect-detail .connect-tabs .connect-navbar a {
    padding: 20px;
  }
  .connect-page.connect-detail .connect-tabs .tabs-body {
    padding: 30px;
  }
  .wHours-row {
    flex-wrap: wrap;
    align-items: center;
  }
  .working-hours .selectdiv {
    width: 165px;
  }
}

@media (min-width: 600px) {
  body .MuiToolbar-gutters {
    padding-left: 0px;
  }
}

@media (max-width: 360px) {
  .connect-page .connect-box {
    width: 130px;
  }
  .connect-page .connect-box .connectLogo img {
    width: 95px;
  }
}

.MTableToolbar-searchField-10 {
  padding-left: 0 !important;
}

.MuiTextField-root .jss10 {
  padding-left: 0 !important;
}

.MTableToolbar-searchField-10 {
  padding-left: 0 !important;
}
.jss10 {
  padding-left: 0 !important;
}
.jss7 {
  display: none;
}

//TODO: Remove this when bootstrap is not used in the project. This is a hotfix to show close button in bootstrap modal component when tailwind css is present
.btn-close {
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat !important;
}